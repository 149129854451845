import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class PartManagerConfigurarRebateService extends BaseService
{
    private _domain: string = '/part-manager/rebate-settings';

    constructor(private accountService: AccountService, private config: SettingsService) {
        super();
    }

    public adicionarIncentivo(holding_id_repairer, rebate) {
        if (isEmpty(this.usuarioLogado)) {
            this.accountService.logout();
            return EMPTY;
        }

        const holding_id_supplier = this.usuarioLogado.holding_id;
        const body = { holding_id_supplier, holding_id_repairer, rebate };

        return this.config.executePost({ url: `${this._domain}/repairer`, body });
    }

    public carregarDadosHolding() {
        return this.config.executeGet({ url: `${this._domain}` });
    }

    public removerIncentivo(holding_id_repairer) {
        return this.config.executeDelete({ url: `${this._domain}/repairer/${holding_id_repairer}` });
    }

    public salvar(rebate: number)
    {
        if (isEmpty(this.usuarioLogado)) {
            this.accountService.logout();
            return EMPTY;
        }

        const holding_id_supplier = this.usuarioLogado.holding_id;
        const body = { holding_id_supplier, holding_id_repairer: 0, rebate };

        return this.config.executePost({ url: `${this._domain}`, body });
    }
}
