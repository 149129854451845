import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterPageComponent } from '../general/master-page/master-page.component';

import { PartManagerAtualizarEstoqueComponent } from './atualizar-estoque/atualizar-estoque.component';
import { PartManagerConfigurarCashbackComponent } from './configurar-cashback/configurar-cashback.component';
import { PartManagerConfigurarFornecimentoComponent } from './configurar-fornecimento/configurar-fornecimento.component';
import { PartManagerConfigurarRebateComponent } from './configurar-rebate/configurar-rebate.component';
import { PartManagerGerenciarVendedoresComponent } from './gerenciar-vendedores/gerenciar-vendedores.component';
import { PartManagerRelatorioFinanceiroComponent } from './relatorio-financeiro/relatorio-financeiro.component';
import { SellerCockpitComponent } from '../seller/cockpit/cockpit.component';
import { SellerDocumentacaoComponent } from '../seller/documentacao/documentacao.component';

const partManagerRouterConfig: Routes = [
    {
        path: '', component: MasterPageComponent,
        children: [
            { path: 'atualizar-estoque', component: PartManagerAtualizarEstoqueComponent },
            { path: 'configurar-cashback', component: PartManagerConfigurarCashbackComponent },
            { path: 'configurar-fornecimento', component: PartManagerConfigurarFornecimentoComponent },
            { path: 'configurar-rebate', component: PartManagerConfigurarRebateComponent },
            { path: 'cockpit', component: SellerCockpitComponent },
            { path: 'documentacao', component: SellerDocumentacaoComponent },
            { path: 'gerenciar-vendedores', component: PartManagerGerenciarVendedoresComponent},
            { path: 'relatorio-financeiro', component: PartManagerRelatorioFinanceiroComponent }
        ]
    }
];

@NgModule({
    imports:
        [
            RouterModule.forChild(partManagerRouterConfig)
        ],
    exports: [RouterModule]
})
export class PartManagerRoutingModule { }

