import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class PartManagerConfigurarCashbackService extends BaseService
{
    private _domain: string = '/part-manager/cashback-settings';

    constructor(private accountService: AccountService, private config: SettingsService) {
        super();
    }

    public carregarDadosHolding() {
        return this.config.executeGet({ url: `${this._domain}` });
    }

    public salvar(has_cashback: boolean, cashback_profit_margin: number) {
        let usuarioLogado = this.usuarioLogado;

        if (isEmpty(usuarioLogado)) {
            this.accountService.logout();
            return EMPTY;
        }

        const holding_id = usuarioLogado.holding_id;
        const body = { holding_id, has_cashback, cashback_profit_margin };

        return this.config.executePost({ url: `${this._domain}`, body });
    }
}
