import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { NgbAccordionModule, NgbCollapseModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxCurrencyDirective  } from "ngx-currency";
import { NgxLoadingModule } from 'ngx-loading';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MatStepperModule } from '@angular/material/stepper';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TooltipModule } from 'ng2-tooltip-directive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NotaFiscalModule } from '../_componentes/notafiscal/notafiscal.module';
import { PartManagerAtualizarEstoqueComponent } from './atualizar-estoque/atualizar-estoque.component';
import { PartManagerConfigurarCashbackComponent } from './configurar-cashback/configurar-cashback.component';
import { PartManagerConfigurarFormaPagamentoComponent } from './_componentes/configurar-forma-pagamento/configurar-forma-pagamento.component';
import { PartManagerConfigurarFornecimentoComponent } from './configurar-fornecimento/configurar-fornecimento.component';
import { PartManagerConfigurarFreteComponent } from './_componentes/configurar-frete/configurar-frete.component';
import { PartManagerConfigurarGeralComponent } from './_componentes/configurar-geral/configurar-geral.component';
import { PartManagerConfigurarPrazoEntregaComponent } from './_componentes/configurar-prazo-entrega/configurar-prazo-entrega.component';
import { PartManagerConfigurarRebateComponent } from './configurar-rebate/configurar-rebate.component';
import { PartManagerGerenciarVendedoresComponent } from './gerenciar-vendedores/gerenciar-vendedores.component';
import { PartManagerRelatorioFinanceiroComponent } from './relatorio-financeiro/relatorio-financeiro.component';

import { PartManagerRoutingModule } from './part-manager.route';
import { SupportGerenciarVendedoresModule } from '../support/_componentes/gerenciar-vendedores/gerenciar-vendedores.module';

import { SettingsService } from '../_services/settings.service';
import { SharedModule } from 'app/_modules/shared.module';
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule } from 'ngx-scrollbar';

export function HttpLoaderFactory(http: HttpClient) { return new TranslateHttpLoader(http); }

@NgModule({
    declarations:
        [
            PartManagerAtualizarEstoqueComponent,
            PartManagerConfigurarCashbackComponent,
            PartManagerConfigurarFormaPagamentoComponent,
            PartManagerConfigurarFornecimentoComponent,
            PartManagerConfigurarFreteComponent,
            PartManagerConfigurarGeralComponent,
            PartManagerConfigurarPrazoEntregaComponent,
            PartManagerConfigurarRebateComponent,
            PartManagerGerenciarVendedoresComponent,
            PartManagerRelatorioFinanceiroComponent
        ],
    imports:
        [
            SharedModule,
            CommonModule,
            FormsModule,
            NgbNavModule,
            NgbModule,
            NgbAccordionModule,
            NgbCollapseModule,
            NgxCurrencyDirective,
            NgxLoadingModule,
            NgxUiLoaderModule,
            MatStepperModule,
            PdfViewerModule,
            NgScrollbarModule,
            ReactiveFormsModule,
            TooltipModule,
            TranslateModule.forChild({ loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] } }),

            NotaFiscalModule,
            PartManagerRoutingModule,
            SupportGerenciarVendedoresModule,
        ],
    providers: [
        SettingsService,
        { provide: LOCALE_ID, deps: [SettingsService], useFactory: (settingsService) => settingsService.getLocale() },
        { provide: NG_SCROLLBAR_OPTIONS, useValue: { visibility: 'hover' } }
    ],
    bootstrap: []
})
export class PartManagerModule { }
