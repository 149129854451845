<div class="atualizar-estoque" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="false"
        [title]="'part-manager.atualizar-estoque.titulo' | translate"
    >
        @if (last_update) {
            <span class="ultima-atualizacao">{{ 'part-manager.atualizar-estoque.ultima-atualizacao' | translate}}<br /><b>{{last_update | date:'dd/MM/yyyy HH:mm'}}</b></span>
        }
    </app-header>
    @if (!tem_txt_posicional) {
        <div class="card card-default">
            <mat-stepper linear #stepper (selectionChange)="stepChanged($event)">
                <mat-step [stepControl]="fileTypeForm" [editable]="true">
                    <ng-template matStepLabel>{{ 'part-manager.atualizar-estoque.passos.passo-1.subtitulo' | translate}}</ng-template>
                    <ng-scrollbar>
                        <div class="conteudo-passo">
                            @if (is_feedbacking) {
                                <div class="is_feedbacking fade-in" [ngClass]="tipo_resultado === 1 ? 'bg-success' : (tipo_resultado === 2 ? 'bg-warning' : 'bg-danger')">
                                    <span>{{ (tipo_resultado === 1 ? 'part-manager.atualizar-estoque.passos.passo-1.sucesso.titulo' : (tipo_resultado === 2 ? 'part-manager.atualizar-estoque.passos.passo-1.erro-upload.titulo-aviso' : 'part-manager.atualizar-estoque.passos.passo-1.erro-upload.titulo-erro')) | translate}}</span>
                                    <div class="break"></div>
                                    <small>{{ (tipo_resultado === 1 ? 'part-manager.atualizar-estoque.passos.passo-1.sucesso.subtitulo' : 'part-manager.atualizar-estoque.passos.passo-1.erro-upload.subtitulo') | translate}}</small>
                                    @if (tipo_resultado > 1) {
                                        <div class="break"></div>
                                        <div class="break"></div>
                                        <div class="erro_upload">
                                            <a href="javascript:void(0)" (click)="atualizarEstoque_Reiniciar()">{{ 'part-manager.atualizar-estoque.passos.passo-1.erro-upload.reiniciar' | translate}}</a>
                                            &nbsp;
                                            <a href="javascript:void(0)" (click)="atualizarEstoque_BaixarLogErro()">{{ 'part-manager.atualizar-estoque.passos.passo-1.erro-upload.baixar-log' | translate}}</a>
                                        </div>
                                    }
                                </div>
                            } @else {
                                <fieldset>
                                    <legend class="titulo-passo">{{ 'part-manager.atualizar-estoque.passos.passo-1.subtitulo' | translate}}</legend>
                                    <form [formGroup]="fileTypeForm">
                                        <div class="form-check">
                                            <label><input required type="radio" name="fileType" formControlName="fileType" value=".csv">{{ 'part-manager.atualizar-estoque.passos.passo-1.opcao-1' | translate}}</label>
                                        </div>
                                        <div class="form-check">
                                            <label><input required type="radio" name="fileType" formControlName="fileType" value=".txt">{{ 'part-manager.atualizar-estoque.passos.passo-1.opcao-2' | translate}}</label>
                                        </div>
                                    </form>
                                </fieldset>
                            }
                        </div>
                    </ng-scrollbar>
                    <div>
                        @if (!is_feedbacking) {
                            <button class="btn btn-primary" (click)="showNextStep()">{{ 'elemento.generico-botao.proximo' | translate }}</button>
                        }
                    </div>
                </mat-step>
                <mat-step [completed]="hasFile" [editable]="true">
                    <ng-template matStepLabel>{{ 'part-manager.atualizar-estoque.passos.passo-2.subtitulo' | translate}}</ng-template>
                    <ng-scrollbar>
                        <div class="conteudo-passo">
                            <fieldset>
                                <legend class="titulo-passo">{{ 'part-manager.atualizar-estoque.passos.passo-2.subtitulo' | translate}}</legend>
                                <div>
                                    <div class="col-md-12">
                                        <div class="alert alert-warning text-center text-bold" role="alert">
                                            {{'part-manager.atualizar-estoque.passos.passo-2.dica-transformar-zip' | translate}}
                                        </div>
                                    </div>
                                    <div class="upload-file">
                                        @if (!fileSelected) {
                                            <span class="btn btn-outline-primary btn-sm btn-file">
                                                {{'part-manager.atualizar-estoque.passos.passo-2.botao.sem-arquivo' | translate}}
                                                <input type="file" id="fileUpload" [accept]="uploadFormatAccepted" (change)="selectArquivo()">
                                            </span>
                                        } @else {
                                            <div class="actions-buttons">
                                                <button class="btn btn-danger btn-sm" (click)="uploadLimpar()">{{'part-manager.atualizar-estoque.passos.passo-2.botao.limpar' | translate}}</button>
                                                @if (!hasFile) {
                                                    <button class="btn btn-primary btn-sm" (click)="uploadArquivo($event)">{{'part-manager.atualizar-estoque.passos.passo-2.botao.upload' | translate}}</button>
                                                }
                                            </div>
                                        }
                                        <p>
                                            {{ fileSelected ? filename : ('part-manager.atualizar-estoque.passos.passo-2.mensagens.tamanho' | translate: { fileTypesAllowed: uploadFormatAccepted, size: uploadMaxSizeAccepted }) }}
                                        </p>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </ng-scrollbar>
                    <div>
                        <button class='btn btn-light mr-sm' (click)="showPreviousStep()">{{ 'elemento.generico-botao.anterior' | translate }}</button>
                        <button class="btn btn-primary" (click)="showNextStep()">{{ 'elemento.generico-botao.proximo' | translate }}</button>
                    </div>
                </mat-step>
                <mat-step [completed]="hasSelectedColumns" [editable]="true">
                    <ng-template matStepLabel>{{ 'part-manager.atualizar-estoque.passos.passo-3.subtitulo' | translate}}</ng-template>
                    <ng-scrollbar>
                        <div class="conteudo-passo">
                            <fieldset>
                                <legend class="titulo-passo">{{ 'part-manager.atualizar-estoque.passos.passo-3.subtitulo' | translate}}</legend>
                                <form name="colunasForm" class="form-config form-validate" [formGroup]="colunasForm">
                                    <div class="linha">
                                        <div class="col form-group">
                                            <div class="cabecalho">
                                                <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.part-number' | translate}}<em class="text-danger">*</em></label>
                                            </div>
                                            <select class="form-control" name="part_number" formControlName="part_number">
                                                @for (coluna of colunas; track coluna; let ind = $index) {
                                                    <option value="{{ind}}">{{coluna}}</option>
                                                }
                                            </select>
                                        </div>
                                        <div class="col form-group">
                                            <div class="cabecalho">
                                                <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.descricao.titulo' | translate}}</label>
                                                <i class="fa fa-info-circle ml5 mb5" tooltip="{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.descricao.subtitulo' | translate}}"></i>
                                            </div>
                                            <select class="form-control" name="descricao" formControlName="descricao">
                                                @for (coluna of colunas; track coluna; let ind = $index) {
                                                    <option value="{{ind}}">{{coluna}}</option>
                                                }
                                            </select>
                                        </div>
                                        <div class="col form-group">
                                            <div class="cabecalho">
                                                <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.montadora.titulo' | translate}}</label>
                                            </div>
                                            <select class="form-control" name="montadora" formControlName="montadora">
                                                @for (coluna of colunas; track coluna; let ind = $index) {
                                                    <option value="{{ind}}">{{coluna}}</option>
                                                }
                                            </select>
                                        </div>
                                        <div class="col form-group">
                                            <div class="cabecalho">
                                                <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.preco' | translate}}<em class="text-danger">*</em></label>
                                            </div>
                                            <select class="form-control" name="preco" formControlName="preco">
                                                @for (coluna of colunas; track coluna; let ind = $index) {
                                                    <option value="{{ind}}">{{coluna}}</option>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div class="linha">
                                        <div class="col form-group">
                                            <div class="cabecalho">
                                                <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.desconto.titulo' | translate}}<em class="text-danger">*</em></label>
                                            </div>
                                            @if (dados_holding_ftp?.has_linear_discount) {
                                                <span class="desconto-linear">{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.desconto.linear' | translate}} {{ dados_holding_ftp?.desconto_linear | currency:' ' }}%</span>
                                            } @else {
                                                <select class="form-control" name="desconto" formControlName="desconto">
                                                    @for (coluna of colunas; track coluna; let ind = $index) {
                                                        <option value="{{ind}}">{{coluna}}</option>
                                                    }
                                                </select>
                                            }
                                        </div>
                                        <div class="col form-group">
                                            <div class="cabecalho">
                                                <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.quantidade' | translate}}<em class="text-danger">*</em></label>
                                            </div>
                                            <select class="form-control" name="quantidade" formControlName="quantidade">
                                                @for (coluna of colunas; track coluna; let ind = $index) {
                                                    <option value="{{ind}}">{{coluna}}</option>
                                                }
                                            </select>
                                        </div>
                                        <div class="col form-group">
                                            <div class="cabecalho">
                                                <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.marca.titulo' | translate}}</label>
                                                <i class="fa fa-info-circle ml5 mb5" tooltip="{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.marca.subtitulo' | translate}}"></i>
                                            </div>
                                            <select class="form-control" name="marca" formControlName="marca">
                                                @for (coluna of colunas; track coluna; let ind = $index) {
                                                    <option value="{{ind}}">{{coluna}}</option>
                                                }
                                            </select>
                                            <div class="sub-col"></div>
                                        </div>
                                        <div class="col form-group">
                                            <div class="cabecalho">
                                                <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.marca-cod.titulo' | translate}}</label>
                                            </div>
                                            <div class="break"></div>
                                            <div class="sub-col">
                                                <select class="form-control" name="marca_cod" formControlName="marca_cod">
                                                    @for (coluna of colunas; track coluna; let ind = $index) {
                                                        <option value="{{ind}}">{{coluna}}</option>
                                                    }
                                                </select>
                                            </div>
                                            <div class="sub-col"></div>
                                        </div>
                                    </div>
                                    <div class="linha">
                                        <div class="col form-group">
                                            <div class="cabecalho">
                                                <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.tipo-peca.titulo' | translate}}<em class="text-danger">*</em></label>
                                                <div class="checkbox c-checkbox needsclick checkboxcot">
                                                    <label class="needsclick">
                                                        <input type="checkbox" class="needsclick" name="padrao_habilitado" formControlName="padrao_habilitado" (click)="habilitarPreenchimentoPadrao()">
                                                        <span class="fa fa-check"></span>
                                                    </label>
                                                    {{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.tipo-peca.padrao' | translate}}
                                                </div>
                                            </div>
                                            <select class="form-control" name="tipo_peca" formControlName="tipo_peca" [disabled]="colunasForm.value.padrao_habilitado ? 'disabled' : null">
                                                @for (coluna of colunas; track coluna; let ind = $index) {
                                                    <option value="{{ind}}">{{coluna}}</option>
                                                }
                                            </select>
                                        </div>
                                        <div class="col form-group"></div>
                                        <div class="col form-group"></div>
                                        <div class="col form-group">
                                            @if (colunasForm.value.padrao_habilitado && !coluna.padrao_habilitado_fixo) {
                                                <div>
                                                    <div class="cabecalho">
                                                        <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.tipo-peca.selecione' | translate}}</label>
                                                    </div>
                                                    <select class="form-control" name="tipo_peca_padrao" formControlName="tipo_peca_padrao" [disabled]="coluna.padrao_habilitado_fixo ? 'disabled' : null">
                                                        @if (checarPartType(1)) {
                                                            <option value="1">{{ 'elemento.tipo-pecas.genuina-2' | translate }}</option>
                                                        }
                                                        @if (checarPartType(2)) {
                                                            <option value="2">{{ 'elemento.tipo-pecas.original-2' | translate }}</option>
                                                        }
                                                        @if (checarPartType(4)) {
                                                            <option value="4">{{ 'elemento.tipo-pecas.alternativa-2' | translate }}</option>
                                                        }
                                                    </select>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </fieldset>
                        </div>
                    </ng-scrollbar>
                    <div>
                        <button class='btn btn-light mr-sm' (click)="showPreviousStep()">{{ 'elemento.generico-botao.anterior' | translate }}</button>
                        <button class="btn btn-primary" (click)="showNextStep()">{{ 'elemento.generico-botao.proximo' | translate }}</button>
                    </div>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>{{ 'part-manager.atualizar-estoque.passos.passo-4.subtitulo' | translate }}</ng-template><ng-scrollbar>
                    <div class="conteudo-passo">
                        @if (!is_feedbacking) {
                            <fieldset>
                                <legend class="titulo-passo">{{ 'part-manager.atualizar-estoque.passos.passo-4.subtitulo' | translate}}</legend>
                                <div class="confirmacao">
                                    <div class="linha">
                                        <div class="col">
                                            &#8226;
                                            <label>{{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.tipo-arquivo' | translate}}</label>
                                            <span>{{ (this.fileTypeForm.value.fileType === '.csv' ? 'part-manager.atualizar-estoque.passos.passo-1.opcao-1' : 'part-manager.atualizar-estoque.passos.passo-1.opcao-2') | translate}}</span>
                                        </div>
                                        <div class="col">
                                            &#8226;
                                            <label>{{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.nome-arquivo' | translate}}</label>
                                            <span>{{ filename != null ? filename : '' }}</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="linha">
                                        <div class="col">
                                            &#8226;
                                            <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.part-number' | translate}}:</label>
                                            <span>{{ colunas[colunasForm.value.part_number] }} <small>({{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.posicao' | translate}} {{colunasForm.value.part_number}})</small></span>
                                        </div>
                                        <div class="col">
                                            &#8226;
                                            <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.preco' | translate}}:</label>
                                            <span>{{ colunas[colunasForm.value.preco] }} <small>({{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.posicao' | translate}} {{colunasForm.value.preco}})</small></span>
                                        </div>
                                    </div>
                                    <div class="linha">
                                        <div class="col">
                                            &#8226;
                                            <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.desconto.titulo' | translate}}:</label>
                                            @if (!dados_holding_ftp?.has_linear_discount) {
                                                <span>{{ colunas[colunasForm.value.desconto] }} <small>({{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.posicao' | translate}} {{colunasForm.value.desconto}})</small></span>
                                            } @else {
                                                <span>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.desconto.linear' | translate}} {{ dados_holding_ftp?.desconto_linear | currency:' ' }}%</span>
                                            }
                                        </div>
                                        <div class="col">
                                            @if (colunasForm.value.montadora > 0) {
                                                <div>
                                                    &#8226;
                                                    <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.montadora.titulo' | translate}}:</label>
                                                    <span>{{ colunas[colunasForm.value.montadora] }} <small>({{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.posicao' | translate}} {{colunasForm.value.montadora}})</small></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="linha">
                                        <div class="col">
                                            &#8226;
                                            <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.quantidade' | translate}}:</label>
                                            <span>{{ colunas[colunasForm.value.quantidade] }} <small>({{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.posicao' | translate}} {{colunasForm.value.quantidade}})</small></span>
                                        </div>
                                        <div class="col">
                                            &#8226;
                                            <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.descricao.titulo' | translate}}:</label>
                                            @if (colunasForm.value.descricao > 0) {
                                                <span>{{ colunas[colunasForm.value.descricao] }} <small>({{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.posicao' | translate}} {{colunasForm.value.descricao}})</small></span>
                                            } @else {
                                                <span>{{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.vazio' | translate}}</span>
                                            }
                                        </div>
                                    </div>
                                    <div class="linha">
                                        <div class="col">
                                            @if (!colunasForm.value.padrao_habilitado) {
                                                <div>
                                                    &#8226;
                                                    <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.marca.titulo' | translate}}:</label>
                                                    @if (colunasForm.value.marca > 0) {
                                                        <span>{{ colunas[colunasForm.value.marca] }} <small>({{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.posicao' | translate}} {{colunasForm.value.marca}})</small></span>
                                                    } @else {
                                                        <span>{{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.vazio-marca' | translate}}</span>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div class="col">
                                            @if (!colunasForm.value.padrao_habilitado) {
                                                <div>
                                                    &#8226;
                                                    <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.marca-cod.titulo' | translate}}:</label>
                                                    @if (colunasForm.value.marca > 0) {
                                                        <span>{{ colunas[colunasForm.value.marca_cod] }} <small>({{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.posicao' | translate}} {{colunasForm.value.marca_cod}})</small></span>
                                                    } @else {
                                                        <span>{{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.vazio' | translate}}</span>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="linha">
                                        <div class="col">
                                            &#8226;
                                            <label>{{ 'part-manager.atualizar-estoque.passos.passo-3.colunas.tipo-peca.titulo' | translate}}:</label>
                                            @if (!colunasForm.value.padrao_habilitado) {
                                                <span>{{ colunas[colunasForm.value.tipo_peca] }} <small>({{ 'part-manager.atualizar-estoque.passos.passo-4.dados-confirmacao.posicao' | translate}} {{colunasForm.value.tipo_peca}})</small></span>
                                            } @else {
                                                <span>{{ (colunasForm.value.tipo_peca_padrao == '1' ? 'elemento.tipo-pecas.genuina-2' : (colunasForm.value.tipo_peca_padrao == '2' ? 'elemento.tipo-pecas.original-2' : 'elemento.tipo-pecas.alternativa-2')) | translate }}</span>
                                            }
                                        </div>
                                        <div class="col"></div>
                                    </div>
                                </div>
                            </fieldset>
                        }
                    </div>
                </ng-scrollbar>
                <div>
                    <button class='btn btn-light mr-sm' (click)="showPreviousStep()">{{ 'elemento.generico-botao.anterior' | translate }}</button>
                    <button class='btn btn-primary' (click)="atualizarEstoque()">{{ 'part-manager.atualizar-estoque.passos.passo-4.botao' | translate }}</button>                            </div>
                </mat-step>
            </mat-stepper>
        </div>
    } @else {
        <div class="card card-default">
            <mat-stepper linear #stepper (selectionChange)="stepChanged($event)">
                <mat-step [stepControl]="fileTypeForm" [editable]="true">
                    <ng-template matStepLabel>{{ 'part-manager.atualizar-estoque.passos.passo-1.subtitulo' | translate}}</ng-template>
                    <ng-scrollbar>
                        <div class="conteudo-passo">
                            @if (is_feedbacking) {
                                <div class="is_feedbacking fade-in" [ngClass]="tipo_resultado === 1 ? 'bg-success' : (tipo_resultado === 2 ? 'bg-warning' : 'bg-danger')">
                                    <span>{{ (tipo_resultado === 1 ? 'part-manager.atualizar-estoque.passos.passo-1.sucesso.titulo' : (tipo_resultado === 2 ? 'part-manager.atualizar-estoque.passos.passo-1.erro-upload.titulo-aviso' : 'part-manager.atualizar-estoque.passos.passo-1.erro-upload.titulo-erro')) | translate}}</span>
                                    <div class="break"></div>
                                    <small>{{ (tipo_resultado === 1 ? 'part-manager.atualizar-estoque.passos.passo-1.sucesso.subtitulo' : 'part-manager.atualizar-estoque.passos.passo-1.erro-upload.subtitulo') | translate}}</small>
                                    @if (tipo_resultado > 1) {
                                        <div class="break"></div>
                                        <div class="break"></div>
                                        <div class="erro_upload">
                                            <a href="javascript:void(0)" (click)="atualizarEstoque_Reiniciar()">{{ 'part-manager.atualizar-estoque.passos.passo-1.erro-upload.reiniciar' | translate}}</a>
                                            &nbsp;
                                            <a href="javascript:void(0)" (click)="atualizarEstoque_BaixarLogErro()">{{ 'part-manager.atualizar-estoque.passos.passo-1.erro-upload.baixar-log' | translate}}</a>
                                        </div>
                                    }
                                </div>
                            } @else {
                                <fieldset>
                                    <legend class="titulo-passo">{{ 'part-manager.atualizar-estoque.passos.passo-1.subtitulo' | translate}}</legend>
                                    <form [formGroup]="fileTypeForm">
                                        <div class="form-check">
                                            <label><input required type="radio" name="fileType" formControlName="fileType" value=".txt">{{ 'part-manager.atualizar-estoque.passos.passo-1.opcao-3' | translate}}</label>
                                        </div>
                                    </form>
                                </fieldset>
                            }
                        </div>
                    </ng-scrollbar>
                    <div>
                        @if (!is_feedbacking) {
                            <button class="btn btn-primary" (click)="showNextStep()">{{ 'elemento.generico-botao.proximo' | translate }}</button>
                        }
                    </div>
                </mat-step>
                <mat-step [editable]="true">
                    <ng-template matStepLabel>{{ 'part-manager.atualizar-estoque.passos.passo-2.subtitulo' | translate}}</ng-template>
                    <ng-scrollbar>
                        <div>
                            <fieldset>
                                <legend class="titulo-passo">{{ 'part-manager.atualizar-estoque.passos.passo-2-alternativo.subtitulo' | translate}}</legend>
                                <div class="conteudo-passo">
                                    <div class="col-md-12">
                                        <div class="alert alert-warning text-center text-bold" role="alert">
                                            {{'part-manager.atualizar-estoque.passos.passo-2.dica-transformar-zip' | translate}}
                                        </div>
                                    </div>
                                    <div class="upload-file">
                                        @if (!fileSelected) {
                                            <span class="btn btn-outline-primary btn-sm btn-file">
                                                {{'part-manager.atualizar-estoque.passos.passo-2.botao.sem-arquivo' | translate}}
                                                <input type="file" id="fileUpload" [accept]="uploadFormatAccepted" (change)="selectArquivo()">
                                            </span>
                                        } @else {
                                            <div class="actions-buttons">
                                                <button class="btn btn-danger btn-sm" (click)="uploadLimpar()">{{'part-manager.atualizar-estoque.passos.passo-2.botao.limpar' | translate}}</button>
                                                @if (!hasFile) {
                                                    <button class="btn btn-primary btn-sm" (click)="uploadArquivo($event)">{{'part-manager.atualizar-estoque.passos.passo-2.botao.upload' | translate}}</button>
                                                }
                                            </div>
                                        }
                                        <p>
                                            {{ fileSelected ? filename : ('part-manager.atualizar-estoque.passos.passo-2.mensagens.tamanho' | translate: { fileTypesAllowed: uploadFormatAccepted, size: uploadMaxSizeAccepted }) }}
                                        </p>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </ng-scrollbar>
                    <div>
                        <button class='btn btn-light mr-sm' (click)="showPreviousStep()">{{ 'elemento.generico-botao.anterior' | translate }}</button>
                        <button [disabled]="!hasFile" class='btn btn-primary' (click)="atualizarEstoqueAlternativo()">{{ 'part-manager.atualizar-estoque.passos.passo-4.botao' | translate }}</button>
                    </div>
                </mat-step>
            </mat-stepper>
        </div>
    }
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
