import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';

import { HoldingDeliveryTime } from '../_models/holdingDeliveryTime';
import { HoldingPaymentType } from '../_models/holdingPaymentType';
import { HoldingShipping } from '../_models/holdingShipping';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class PartManagerConfigurarFornecimentoService extends BaseService
{
    private _domain: string = '/part-manager/supply-settings';

    constructor(private accountService: AccountService, private config: SettingsService) {
        super();
    }

    public formaPagamentoAdicionar(formaPagamento: HoldingPaymentType) {
        return this.config.executePost({ url: `${this._domain}/forma-pagamento`, body: formaPagamento });
    }

    public formaPagamentoCarregar() {
        return this.config.executeGet({ url: `${this._domain}/forma-pagamento` });
    }

    public formaPagamentoRemover(payment_type_id: number) {
        return this.config.executeDelete({ url: `${this._domain}/forma-pagamento/${payment_type_id}` });
    }

    public freteAdicionar(frete: HoldingShipping) {
        return this.config.executePost({ url: `${this._domain}/frete`, body: frete });
    }

    public freteCarregar() {
        return this.config.executeGet({ url: `${this._domain}/frete` });
    }

    public freteRemover(region_id: number) {
        return this.config.executeDelete({ url: `${this._domain}/frete/${region_id}` });
    }

    public geralDadosAlterar(dias_pedido_fabrica: number, resposta_automatica: boolean) {
        if (isEmpty(this.usuarioLogado))
        {
            this.accountService.logout();
            return EMPTY;
        }

        const holding_id = this.usuarioLogado.holding_id;
        const body = { holding_id, dias_pedido_fabrica, resposta_automatica };

        return this.config.executePost({ url: `${this._domain}/general`, body });
    }

    public geralCarregar() {
        return this.config.executeGet({ url: `${this._domain}/general` });
    }

    public prazoEntregaAdicionar(prazo: HoldingDeliveryTime) {
        return this.config.executePost({ url: `${this._domain}/prazo-entrega`, body: prazo });
    }

    public prazoEntregaCarregar() {
        return this.config.executeGet({ url: `${this._domain}/prazo-entrega` });
     }

    public prazoEntregaRemover(region_id: number) {
        return this.config.executeDelete({ url: `${this._domain}/prazo-entrega/${region_id}` });
    }
}
