<div class="configurar-cashback" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="false"
        [hasButtons]="false"
        [title]="'part-manager.configurar-cashback.titulo' | translate"
    ></app-header>
    <div class="card card-default">
        <ng-scrollbar>
            <form name="cashbackForm" class="form-config form-validate" [formGroup]="cashbackForm" (ngSubmit)="salvar(0)">
                <div class="col">
                    <div class="mb-3 form-group">
                        <label>{{ 'part-manager.configurar-cashback.habilitar-cashback' | translate}}</label>
                        <div class="form-check">
                            <label class="switch switch-lg">
                                <input type="checkbox" formControlName="has_cashback" (change)="has_cashback = !has_cashback" />
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col">
                    @if (has_cashback) {
                        <div class="mb-3 form-group">
                            <label>{{ 'part-manager.configurar-cashback.profit-margin.label' | translate}} <small class="text-danger">{{ 'part-manager.configurar-cashback.profit-margin.min-max' | translate}}</small></label>
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control form-control-sm" name="cashback_profit_margin" formControlName="cashback_profit_margin" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="10" maxlength="4" (blur)="calcularDetalhamento()" />
                                <span class="input-group-text"><i class="fa fa-percent" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    }
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-primary btn-sm" [disabled]="!cashbackForm.valid || !cashbackForm.dirty ? 'disabled' : null">{{ 'part-manager.configurar-cashback.botao-salvar' | translate}}</button>
                </div>
                <div class="col"></div>
            </form>

            <hr />

            @if (has_cashback) {
                <div>
                    <fieldset>
                        <legend>{{ 'part-manager.configurar-cashback.detalhamento.legend.titulo' | translate}} <label>{{ 'part-manager.configurar-cashback.detalhamento.legend.label' | translate}}</label></legend>
                        <div class="detalhes">
                            <div class="col">
                                <ul>
                                    <li>{{ 'part-manager.configurar-cashback.detalhamento.sem.titulo' | translate}}</li>
                                    <li><span>{{ 'part-manager.configurar-cashback.detalhamento.sem.valor-peca' | translate}}:</span> {{cashback.valor_peca | currency:"R$ "}}</li>
                                    <li><span>{{ 'part-manager.configurar-cashback.detalhamento.sem.desconto' | translate}}:</span> {{cashback.desconto_original}}%</li>
                                    <li class="green"><span>{{ 'part-manager.configurar-cashback.detalhamento.sem.valor-venda' | translate}}:</span> {{cashback.valor_venda_original | currency:"R$ "}}</li>
                                </ul>
                            </div>
                            <div class="col">
                                <ul>
                                    <li>{{ 'part-manager.configurar-cashback.detalhamento.com.titulo' | translate}}</li>
                                    <li><span>{{ 'part-manager.configurar-cashback.detalhamento.com.valor-peca' | translate}}:</span> {{cashback.valor_peca | currency:"R$ "}}</li>
                                    <li><span>{{ 'part-manager.configurar-cashback.detalhamento.com.desconto' | translate}}:</span> {{cashback.desconto_cashback | currency:" "}}%</li>
                                    <li class="green"><span>{{ 'part-manager.configurar-cashback.detalhamento.com.valor-venda' | translate}}:</span> {{cashback.valor_venda_cashback | currency:"R$ "}}</li>
                                </ul>
                            </div>
                            <div class="col">
                                <ul>
                                    <li>{{ 'part-manager.configurar-cashback.detalhamento.analise.titulo' | translate}}</li>
                                    <li><span>{{ 'part-manager.configurar-cashback.detalhamento.analise.com-cashback.parte-1' | translate}} <small>{{ 'part-manager.configurar-cashback.detalhamento.analise.com-cashback.parte-2' | translate}}</small>:</span> {{ 'part-manager.configurar-cashback.detalhamento.analise.com-cashback.parte-3' | translate}} {{cashback.com_cashback | currency:"R$"}}</li>
                                    <li class="text-danger"><span>{{ 'part-manager.configurar-cashback.detalhamento.analise.desconta-imposto' | translate}} <small>({{cashback.imposto | currency:" "}}%)</small>:</span> -{{cashback.imposto_descontado | currency:"R$ "}}</li>
                                    <li style="border-bottom: solid 1px #eee;"><span>{{ 'part-manager.configurar-cashback.detalhamento.analise.cashback-efetivo' | translate}}:</span> {{cashback.cashback_efetivo | currency:"R$ "}}</li>
                                    <li><span>{{ 'part-manager.configurar-cashback.detalhamento.analise.devolve-comprador' | translate}}:</span> {{cashback.comprador | currency:"R$ "}}</li>
                                    <li><span>{{ 'part-manager.configurar-cashback.detalhamento.analise.taxa-partsfy' | translate }} <small>({{cashback.taxa_partsfy | currency:" "}}%)</small>:</span> {{cashback.partsfy | currency:"R$ "}}</li>
                                    <li [ngClass]="cashback.vendedor >= 0 ? 'text-success' : 'text-danger'"><span>{{ 'part-manager.configurar-cashback.detalhamento.analise.lucro-extra' | translate}}:</span> {{cashback.vendedor | currency:"R$ "}}</li>
                                    <li [ngClass]="cashback.vendedor >= 0 ? 'text-success' : 'text-danger'"><span>{{ 'part-manager.configurar-cashback.detalhamento.analise.margem-extra' | translate}}:</span> {{cashback.vendedor_margem | currency:" "}}%</li>
                                    <li>
                                        @if (cashbackForm.valid) {
                                            <a href="javascript:void(0)" (click)="salvar(0)">{{ 'part-manager.configurar-cashback.detalhamento.analise.clique-salvar' | translate}}</a>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </fieldset>
                </div>
            }
        </ng-scrollbar>
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
