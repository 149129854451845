<div class="configurar-fornecimento" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [title]="'part-manager.configurar-fornecimento.titulo' | translate"
        [hasBackButton]="false"
    >
        <button class="btn btn-light btn-sm mt0" (click)="openTutorial()" >{{'part-manager.configurar-fornecimento.tutorial' | translate}}</button>
    </app-header>
    <div class="card card-default">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1" [ngClass]="active === 1 ? 'active' : ''">
                <a ngbNavLink>{{ 'part-manager.configurar-fornecimento.abas.forma-pagamento' | translate}}</a>
                <ng-template ngbNavContent>
                    <app-configurar-forma-pagamento [formas]="formas" [formas_pagamento]="formas_pagamento" [inicializar]="inicializarFormaPagamento"></app-configurar-forma-pagamento>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" [ngClass]="active === 2 ? 'active' : ''">
                <a ngbNavLink>{{ 'part-manager.configurar-fornecimento.abas.frete' | translate}}</a>
                <ng-template ngbNavContent>
                    <app-configurar-frete [fretes]="fretes" [regioes]="regioes" [inicializar]="inicializarFrete"></app-configurar-frete>
                </ng-template>
            </li>
            <li [ngbNavItem]="3" [ngClass]="active === 3 ? 'active' : ''">
                <a ngbNavLink>{{ 'part-manager.configurar-fornecimento.abas.prazo-entrega' | translate}}</a>
                <ng-template ngbNavContent>
                    <app-configurar-prazo-entrega (alterarGeral)="alterarGeral($event, 0)" [dados]="geral_dados" [prazos]="prazos" [prazos_entrega]="prazos_entrega" [regioes]="regioes" [inicializar]="inicializarPrazo"></app-configurar-prazo-entrega>
                </ng-template>
            </li>
            <!--<li [ngbNavItem]="4" [ngClass]="active === 4 ? 'active' : ''">
                <a ngbNavLink>{{ 'part-manager.configurar-fornecimento.abas.geral' | translate}}</a>
                <ng-template ngbNavContent>
                    <app-configurar-geral (alterarGeral)="alterarGeral($event)" [dados]="geral_dados"></app-configurar-geral>
                </ng-template>
            </li>-->
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
