<div class="configurar-rebate" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [title]="'part-manager.configurar-rebate.titulo' | translate"
        [subtitle]="'part-manager.configurar-rebate.subtitulo' | translate"
        [hasBackButton]="false"
    >
        <form name="rebateForm" class="form-config form-validate" [formGroup]="rebateForm" (ngSubmit)="salvar(0, false)">
            <div class="col col-5">
                <div class="form-group">
                    <label>{{ 'part-manager.configurar-rebate.valor-padrao.label' | translate}} <small class="text-danger">{{ 'part-manager.configurar-rebate.valor-padrao.min-max' | translate}}</small></label>
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control form-control-sm" name="rebate" formControlName="rebate" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="1" max="20" maxlength="4" />
                        <span class="input-group-text"><i class="fa fa-percent" aria-hidden="true"></i></span>
                    </div>
                </div>
            </div>
            <div class="col botao-header">
                <button type="submit" class="btn btn-primary btn-sm" [disabled]="!rebateForm.valid || !rebateForm.dirty ? 'disabled' : null">{{ 'elemento.generico-botao.salvar' | translate}}</button>
            </div>
            <div class="col col-3"></div>
        </form>
    </app-header>
    <div class="card card-default">

        @if (rebate_inativo && visivel) {
            <div class="cta-rebate">
                <label>{{ 'part-manager.configurar-rebate.inativo.label' | translate}}</label>
                <a class="btn btn-sm btn-primary text-uppercase" href="https://wa.me/5511940489535?text=Gostaria%20de%20saber%20mais%20sobre%20cashback%20padronizado" target="_blank">{{ 'part-manager.configurar-rebate.inativo.botao' | translate}}</a>
            </div>
        }

        @if (!rebate_inativo && usuarioLocal.plan === 2) {
            <form name="rebateForm" class="form-config form-validate" [formGroup]="rebateForm" (ngSubmit)="salvar(0, false)">
                <div class="col col-2">
                    <div class="mb-3 form-group">
                        <label>{{ 'part-manager.configurar-rebate.valor-padrao.label' | translate}} <small class="text-danger">{{ 'part-manager.configurar-rebate.valor-padrao.min-max' | translate}}</small></label>
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" name="rebate" formControlName="rebate" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="1" max="20" maxlength="4" />
                            <span class="input-group-text"><i class="fa fa-percent" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col botao">
                    <button type="submit" class="btn btn-primary btn-sm" [disabled]="!rebateForm.valid || !rebateForm.dirty ? 'disabled' : null">{{ 'elemento.generico-botao.salvar' | translate}}</button>
                </div>
                <div class="col col-3"></div>
            </form>
        }

        @if (!rebate_inativo && usuarioLocal.plan === 3) {
            <fieldset class="area-incentivo d-flex flex-wrap">
                <div class="titulo">
                    <span>{{ 'part-manager.configurar-rebate.area-incentivo.titulo' | translate}}</span>
                    <small>{{ 'part-manager.configurar-rebate.area-incentivo.subtitulo' | translate}}</small>
                </div>

                <section class="d-flex flex-wrap striped">
                    <header>
                        <div class="linha">
                            <div class="col col-2">
                                {{ 'part-manager.configurar-rebate.area-incentivo.tabela.oficina' | translate}}
                            </div>
                            <div class="col rebate-nowrap">
                                {{ 'part-manager.configurar-rebate.area-incentivo.tabela.rebate' | translate}} <small class="text-danger">{{ 'part-manager.configurar-rebate.valor-padrao.min-max' | translate}}</small>
                            </div>
                            <div class="col"></div>
                        </div>
                        <div class="linha">
                            <form name="rebateOficinaForm" class="form-config form-validate linha" [formGroup]="rebateOficinaForm" (ngSubmit)="incentivo_AdicionarOficina(0, false)">
                                <div class="col col-2">
                                    <select class="form-select" name="holding_id" formControlName="holding_id">
                                        @for (oficina of oficinasNaoSelecionadas; track oficina) {
                                            <option value="{{oficina.holdingId}}">{{oficina.fantasyName}}</option>
                                        }
                                    </select>
                                </div>
                                <div class="col text-end">
                                    <div class="input-group input-group-sm">
                                        <input type="text" class="form-control form-control-sm" name="rebate" formControlName="rebate" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="1" max="20" maxlength="4" />
                                        <span class="input-group-text"><i class="fa fa-percent" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                                <div class="col text-center botao">
                                    <button type="submit" class="btn btn-primary btn-sm" [disabled]="!rebateOficinaForm.valid || !rebateOficinaForm.dirty ? 'disabled' : null">{{ 'elemento.generico-botao.adicionar' | translate}}</button>
                                </div>
                            </form>
                        </div>
                    </header>
                    @if (oficinasSelecionadas?.length > 0) {
                        <div class="corpo">
                            <ng-scrollbar>
                                @for (oficina of oficinasSelecionadas; track oficina) {
                                    <div class="linha">
                                        <div class="col col-2">{{ oficina.fantasyName }}</div>
                                        <div class="col text-end">{{ oficina.rebate | currency:' '}}%</div>
                                        <div class="col text-center">
                                            <i class="fa fa-times fa-1x cursor-pointer text-danger" (click)="incentivo_RemoverOficina(oficina.holdingId, 0)"></i>
                                        </div>
                                    </div>
                                }
                            </ng-scrollbar>
                        </div>
                    } @else {
                        <div class="vazio linha">
                            <div class="col col-3">
                                {{ 'part-manager.configurar-rebate.area-incentivo.vazio' | translate}}
                            </div>
                        </div>
                    }
                </section>
            </fieldset>
        }
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
