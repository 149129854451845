<div class="configurar-forma-pagamento">
    <section class="tabela-forma-pagamento d-flex flex-wrap">
        <header>
            <div class="linha">
                <div class="col">{{'part-manager.configurar-fornecimento.forma-pagamento.colunas.forma' | translate}}</div>
                <div class="col">{{'part-manager.configurar-fornecimento.forma-pagamento.colunas.valor-de' | translate}}</div>
                <div class="col"></div>
            </div>
            <div class="linha">
                <form name="formaPagamentoForm" class="form-validate" [formGroup]="formaPagamentoForm" (ngSubmit)="adicionar(0)">
                    <div class="col">
                        <select class="form-select form-select-sm m-b" formControlName="payment_type_id" [disabled]="(adicionando || formasFiltradas.length === 0) ? 'disabled' : null">
                            @if (formasFiltradas.length === 0) {
                                <option value="0">{{ 'part-manager.configurar-fornecimento.forma-pagamento.sem-forma' | translate}}</option>
                            } @else {
                                @for (forma of formasFiltradas; track forma) {
                                    <option value="{{forma.id}}">{{ forma.texto_x }}</option>
                                }
                            }
                        </select>
                    </div>
                    <div class="col">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text">
                                <span>R$</span>
                            </span>
                            <input type="text" class="form-control form-control-sm" name="start_value" formControlName="start_value" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" maxlength="6" />
                        </div>
                    </div>
                    <div class="col">
                        <input type="submit" [disabled]="formasFiltradas.length === 0 ? 'disabled' : null" value="{{'part-manager.configurar-fornecimento.botoes.adicionar' | translate}}" class="btn btn-sm btn-success" />
                    </div>
                </form>
            </div>
        </header>
        <div class="corpo">
            <ng-scrollbar>
                @if (formas?.length <= 0) {
                    <div class="vazio">
                        {{'part-manager.configurar-fornecimento.forma-pagamento.vazio' | translate}}
                    </div>
                } @else {
                    @for (forma of formas; track forma) {
                        <div class="linha">
                            <div class="col truncate">{{ forma.payment_type_name }}</div>
                            <div class="col text-end">{{ forma.start_value | currency:'R$ ' }}</div>
                            <div class="col">
                                <button class="btn btn-sm btn-danger" (click)="remover(forma.payment_type_id, 0)">
                                    <i class="fa fa-trash"></i>
                                    <span>{{'part-manager.configurar-fornecimento.botoes.remover' | translate}}</span>
                                </button>
                            </div>
                        </div>
                    }
                }
            </ng-scrollbar>
        </div>
    </section>
</div>
