import { Component, OnInit, ViewChild } from '@angular/core';

import { SupportGerenciarVendedoresInternoComponent } from '../../support/_componentes/gerenciar-vendedores/gerenciar-vendedores-interno.component';
import { BaseService } from '../../_services/base.service';

@Component({ selector: 'app-gerenciar-vendedores', templateUrl: './gerenciar-vendedores.component.html', styleUrls: ['./gerenciar-vendedores.component.scss'] })
export class PartManagerGerenciarVendedoresComponent implements OnInit
{
    @ViewChild(SupportGerenciarVendedoresInternoComponent) interno: SupportGerenciarVendedoresInternoComponent;

    holdingSelecionada: any;
    holdingSelecionadaId: number;

    constructor(private baseService: BaseService) { }

    ngOnInit(): void
    {
        this.carregarTela();
    }

    carregarTela()
    {
        let usuarioLocal = this.baseService.usuarioLocal;
        this.holdingSelecionada = { id: usuarioLocal.holding_id, is_billable: usuarioLocal.is_billable };
        this.holdingSelecionadaId = this.holdingSelecionada.id;

        setTimeout(() => this.interno.listarEmpresasAssociadas(this.holdingSelecionada.id, this.holdingSelecionada.is_billable), 500);
    }

    exportarCSV()
    {
        this.interno.exportarCSV();
    }
}
