import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class PartManagerRelatorioFinanceiroService extends BaseService
{
    private _domain: string = '/part-manager/reports';

    constructor(private accountService: AccountService, private config: SettingsService) {
        super();
    }

    public carregarDadosHolding() {
        return this.config.executeGet({ url: `${this._domain}/info` });
    }

    public carregarRelatorio(ano_mes: string) {
        if (!ano_mes)
            return this.config.executeGet({ url: `${this._domain}` });

        return this.config.executeGet({ url: `${this._domain}/${ano_mes}` });
    }

    public contestar(pecas, motivo, ano_mes) {
        if (isEmpty(this.usuarioLogado)) {
            this.accountService.logout();
            return EMPTY;
        }

        const user_company_id = this.usuarioLogado.user_company_id;
        const body = { user_company_id, pecas, motivo, ano_mes };

        return this.config.executePost({ url: `${this._domain}/contestacoes`, body });
    }

    public listarMeses() {
        const quantity = 12;
        return this.config.executeGet({ url: `${this._domain}/months/${quantity}` });
    }
}
