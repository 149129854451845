import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Subject } from 'rxjs';

import { AccountService } from '../../../account/account.service';
import { NotificationService } from '../../../_services/notification.service';
import { PartManagerConfigurarFornecimentoService } from '../../configurar-fornecimento/configurar-fornecimento.service';

import { Globals } from '../../../_models/globals';
import { HoldingPaymentType } from '../../_models/holdingPaymentType';
import { ApiResponseModel } from 'app/_models/apiResponseModel';

declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-configurar-forma-pagamento', templateUrl: './configurar-forma-pagamento.component.html', styleUrls: ['./configurar-forma-pagamento.component.scss']})
export class PartManagerConfigurarFormaPagamentoComponent implements OnInit
{
    @Input() formas: HoldingPaymentType[];
    @Input() inicializar: Subject<boolean>;
    @Input() formas_pagamento: any;

    adicionando: boolean;
    formaPagamentoForm: UntypedFormGroup;
    holding_id: number;

    constructor(
        private accountService: AccountService,
        private notificationService: NotificationService,
        private fornecimentoService: PartManagerConfigurarFornecimentoService,
        private globals: Globals,
        private fb: UntypedFormBuilder) {
        this.holding_id = this.accountService.obterUsuario().holding_id;

        this.formas = [];
        this.formas_pagamento = [];
    }

    ngOnInit(): void {
        this.formaPagamentoForm = this.fb.group(
            {
                payment_type_id: [0, [Validators.required, Validators.min(1)]],
                start_value: [0, [Validators.min(0)]]
            });

        this.adicionando = true;

        if (!isEmpty(this.formas_pagamento))
            this.resetForm(0);

        this.inicializar.subscribe(() => this.resetForm(0));
    }

    adicionar(nAttempts: number) {
        this.adicionando = true;
        let payment_type_id = parseInt(this.formaPagamentoForm.value.payment_type_id);

        let payment_type_name = this.formas_pagamento.find(f => f.id === payment_type_id).texto_x;

        let forma_pagamento: HoldingPaymentType = { holding_id: this.holding_id, payment_type_id, payment_type_name, start_value: this.formaPagamentoForm.value.start_value, is_active: true };
        this.formas.push(forma_pagamento);
        this.formas.sort(sort_by([{ name: 'payment_type_id' }]));

        this.fornecimentoService.formaPagamentoAdicionar(forma_pagamento).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (!isEmpty(response) && response.success) {
                    this.notificationService.showSuccessToastr(this.globals.translate('part-manager.configurar-fornecimento.forma-pagamento.mensagens.sucesso.adicionar.corpo'));
                    this.resetForm(0);
                } else {
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.forma-pagamento.mensagens.erro.adicionar.corpo'));
                    this.remover(payment_type_id, 0);
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.forma-pagamento.mensagens.erro.adicionar.corpo'));
                    return;
                }

                this.remover(payment_type_id, 0);
                this.adicionar(++nAttempts);
            }
        });
    }

    remover(payment_type_id: number, nAttempts: number) {
        let forma = this.formas.find(f => f.payment_type_id === payment_type_id);

        if (isEmpty(forma))
            return;

        var idx = this.formas.indexOf(forma);
        this.formas.splice(idx, 1);

        this.fornecimentoService.formaPagamentoRemover(forma.payment_type_id).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (!isEmpty(response) && response.success)
                    this.notificationService.showSuccessToastr(this.globals.translate('part-manager.configurar-fornecimento.forma-pagamento.mensagens.sucesso.remover.corpo'));

                this.resetForm(0);
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.forma-pagamento.mensagens.erro.remover.corpo'));
                    return;
                }

                this.remover(payment_type_id, ++nAttempts);
            }
        });
    }

    resetForm(nAttempts: number) {
        this.formaPagamentoForm.reset();
        this.formaPagamentoForm.patchValue({ start_value: 0 });

        let filtradas = this.formasFiltradas;

        if (filtradas.length > 0)
            this.formaPagamentoForm.patchValue({ payment_type_id: filtradas[0].id });
        else {
            if (nAttempts < 5)
                setTimeout(() => this.resetForm(++nAttempts), 200);

            this.formaPagamentoForm.patchValue({ payment_type_id: 0 });
            return;
        }

        this.adicionando = false;
    }


    get formasFiltradas() {
        if (isEmpty(this.formas_pagamento))
            return [];

        return this.formas_pagamento.filter(p => this.formas.filter(f => f.payment_type_id === p.id).length <= 0).sort(sort_by([{ name: 'texto_x' }]));
    }
}
