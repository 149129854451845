import { Component, OnInit } from '@angular/core';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';

import { Globals } from '../../_models/globals';
import { HoldingDeliveryTime } from '../_models/holdingDeliveryTime';
import { HoldingPaymentType } from '../_models/holdingPaymentType';
import { HoldingShipping } from '../_models/holdingShipping';

import { NotificationService } from '../../_services/notification.service';
import { PartManagerConfigurarFornecimentoService } from './configurar-fornecimento.service';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { PaymentType } from 'app/_models/paymentType';
import { DeliveryTimeDetailed } from 'app/_models/deliveryTime';

declare const isEmpty: any;

@Component({ selector: 'app-configurar-fornecimento', templateUrl: './configurar-fornecimento.component.html', styleUrls: ['./configurar-fornecimento.component.scss'] })
export class PartManagerConfigurarFornecimentoComponent implements OnInit
{
    active: number;
    geral_dados: { alterando: boolean, dias_pedido_fabrica: number, resposta_automatica: boolean };

    loading_ctr: number;
    loading: Subject<boolean> = new Subject();
    inicializarFormaPagamento: Subject<boolean> = new Subject();
    inicializarFrete: Subject<boolean> = new Subject();
    inicializarPrazo: Subject<boolean> = new Subject();

    formas: HoldingPaymentType[];
    formas_pagamento: any;
    fretes: HoldingShipping[];
    prazos: HoldingDeliveryTime[];
    prazos_entrega: any;
    regioes: any;

    constructor(
        private notificationService: NotificationService,
        private fornecimentoService: PartManagerConfigurarFornecimentoService,
        private globals: Globals,
        private ngxLoader: NgxUiLoaderService) {
        this.formas = [];
        this.fretes = [];
        this.prazos = [];
        this.geral_dados = { alterando: false, dias_pedido_fabrica: 0, resposta_automatica: false };
    }

    ngOnInit(): void {
        this.active = 1;
        this.loading_ctr = 0;
        this.loading.subscribe((valor) =>
        {
            if (valor)
                this.loading_ctr++;

            if (!valor || this.loading_ctr >= 4)
                this.ngxLoader.stopLoader('loader-principal');
        });

        this.carregarTela();
    }

    alterarGeral(param, nAttempts: number) {
        let diasPedidoFabrica: number = param.diasPedidoFabrica;
        let respostaSemRevisao: boolean = param.respostaSemRevisao;
        let tipo: string = param.tipo;

        this.geral_dados.alterando = true;

        this.fornecimentoService.geralDadosAlterar(diasPedidoFabrica, respostaSemRevisao).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (!isEmpty(response) && response.success)
                    this.notificationService.showSuccessToastr(this.globals.translate('part-manager.configurar-fornecimento.geral.mensagens.sucesso.alterar' + tipo + '.corpo'));

                this.geral_dados.alterando = false;
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.geral_dados.alterando = false;
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.geral.mensagens.erro.alterar' + tipo + '.corpo'));
                    return;
                }

                this.alterarGeral(param, ++nAttempts);
            }
        });
    }

    carregarFormaPagamento(nAttempts: number) {
        this.fornecimentoService.formaPagamentoCarregar().subscribe({
            next: (response: ApiResponseModel<{formas: HoldingPaymentType[], formasPagamento: PaymentType[] }>) => {
                if (!isEmpty(response) && response.success) {
                    this.formas = response.result.formas;
                    this.formas_pagamento = response.result.formasPagamento;
                    this.inicializarFormaPagamento.next(true);
                    this.loading.next(true);
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.loading.next(false);
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.forma-pagamento.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarFormaPagamento(++nAttempts);
            }
        });
    }

    carregarFrete(nAttempts: number) {
        this.fornecimentoService.freteCarregar().subscribe({
            next: (response: ApiResponseModel<{fretes: HoldingShipping[], regioes: { region_id: number, region_name: string }[]}>) => {
                if (!isEmpty(response) && response.success) {
                    this.fretes = response.result.fretes;
                    this.regioes = response.result.regioes;
                    this.inicializarFrete.next(true);

                    this.loading_ctr++;
                    this.loading.next(true);
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.loading_ctr = 10;
                    this.loading.next(false);
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.frete.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarFrete(++nAttempts);
            }
        });
    }

    carregarGeral(nAttempts: number) {
        this.fornecimentoService.geralCarregar().subscribe({
            next: (response: ApiResponseModel<{diasPedidoFabrica: number, respostaAutomatica: boolean}>) => {
                if (!isEmpty(response) && response.success) {
                    this.geral_dados.alterando = false;
                    this.geral_dados.dias_pedido_fabrica = response.result.diasPedidoFabrica;
                    this.geral_dados.resposta_automatica = response.result.respostaAutomatica;

                    this.loading_ctr++;
                    this.loading.next(true);
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.loading_ctr = 10;
                    this.loading.next(false);
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.geral.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarGeral(++nAttempts);
            }
        });
    }

    carregarPrazoEntrega(nAttempts: number) {
        this.fornecimentoService.prazoEntregaCarregar().subscribe({
            next: (response: ApiResponseModel<{prazos: HoldingDeliveryTime[], prazosEntrega: DeliveryTimeDetailed[], regioes: {region_id: number, region_name: string}}>) => {
                if (!isEmpty(response) && response.success) {
                    this.prazos = response.result.prazos;
                    this.prazos_entrega = response.result.prazosEntrega;
                    this.regioes = response.result.regioes;
                    this.inicializarPrazo.next(true);

                    this.loading_ctr++;
                    this.loading.next(true);
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.loading_ctr = 10;
                    this.loading.next(false);
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.prazo-entrega.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarPrazoEntrega(++nAttempts);
            }
        });
    }

    carregarTela() {
        this.ngxLoader.startLoader('loader-principal');
        this.carregarFormaPagamento(0);
        this.carregarFrete(0);
        this.carregarGeral(0);
        this.carregarPrazoEntrega(0);
    }

    openTutorial() {
        window.open("https://partsfy.crisp.help/pt-br/article/como-configurar-meu-fornecimento-egc93r/?bust=1713223494930", "_blank");
    }
}
