<div class="gerenciar-vendedores" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [title]="'part-manager.gerenciar-vendedores.titulo' | translate"
        [hasBackButton]="false"
    >
        <button type="button" class="btn btn-light btn-sm btn-csv" (click)="exportarCSV()" [disabled]="holdingSelecionadaId == 0">{{ 'part-manager.gerenciar-vendedores.baixar-csv' | translate}}</button>
    </app-header>
    <app-gerenciar-vendedores-interno [holdingSelecionada]="holdingSelecionada" [isFromSupport]="false"></app-gerenciar-vendedores-interno>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
