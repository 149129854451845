<div class="configurar-prazo-entrega">
    <section class="tabela-prazo-entrega">
        <header>
            <div class="linha cabecalho">
                <div class="col">{{'part-manager.configurar-fornecimento.prazo-entrega.colunas.regiao' | translate}}</div>
                <div class="col">{{'part-manager.configurar-fornecimento.prazo-entrega.colunas.prazo' | translate}}</div>
                <div class="col">
                    <div class="input-group">
                        <span class="input-group-text">
                            <span>{{'part-manager.configurar-fornecimento.prazo-entrega.colunas.pedido-fabrica.label' | translate}}</span>
                        </span>

                        <select class="form-select form-select-sm m-b" [(ngModel)]="dias_pedido_fabrica" (change)="alterar()">
                            <option value="0"></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                        </select>

                        <span class="input-group-text">
                            <span>
                                {{ (dias_pedido_fabrica==="0" ? 'part-manager.configurar-fornecimento.prazo-entrega.colunas.pedido-fabrica.ne' : (dias_pedido_fabrica==="1" ? 'part-manager.configurar-fornecimento.prazo-entrega.colunas.pedido-fabrica.du' : 'part-manager.configurar-fornecimento.prazo-entrega.colunas.pedido-fabrica.dus')) | translate}}
                                <div>
                                    @if (alterando) {
                                        <div class="fa fa-spinner spin"><div></div></div>
                                    }
                                </div>
                            </span>
                        </span>

                    </div>
                </div>
            </div>
            <div class="linha">
                <form name="prazoForm" class="form-validate" [formGroup]="prazoForm" (ngSubmit)="adicionar(0)">
                    <div class="col">
                        <select class="form-select form-select-sm m-b" formControlName="region_id" [disabled]="(adicionando || regioesFiltradas.length === 0) ? 'disabled' : null">
                            @if (regioesFiltradas.length === 0) {
                                <option value="0">{{ 'part-manager.configurar-fornecimento.prazo-entrega.sem-regiao' | translate}}</option>
                            } @else {
                                @for (regiao of regioesFiltradas; track regiao) {
                                    <option value="{{regiao.region_id}}">{{ regiao.region_name }}</option>
                                }
                            }
                        </select>
                    </div>
                    <div class="col">
                        <select class="form-select form-select-sm m-b" formControlName="delivery_time_id">
                            @for (prazo of prazos_entrega; track prazo) {
                                <option value="{{prazo.delivery_time_id}}">{{ prazo.delivery_time_name }}</option>
                            }
                        </select>
                    </div>
                    <div class="col">
                        <input type="submit" [disabled]="regioesFiltradas.length === 0 ? 'disabled' : null" value="{{'part-manager.configurar-fornecimento.botoes.adicionar' | translate}}" class="btn btn-sm btn-success" />
                    </div>
                </form>
            </div>
        </header>
        <div class="corpo">
            <ng-scrollbar>
                @if (prazos?.length <= 0) {
                    <div class="vazio">
                        {{'part-manager.configurar-fornecimento.prazo-entrega.vazio' | translate}}
                    </div>
                } @else {
                    @for (prazo of prazos; track prazo) {
                        <div class="linha">
                            <div class="col truncate">{{ prazo.region_name }}</div>
                            <div class="col text-center">{{ prazo.delivery_time_name }}</div>
                            <div class="col">
                                <button class="btn btn-sm btn-danger" (click)="remover(prazo.region_id, 0)">
                                    <i class="fa fa-trash"></i>
                                    <span>{{'part-manager.configurar-fornecimento.botoes.remover' | translate}}</span>
                                </button>
                            </div>
                        </div>
                    }
                }
            </ng-scrollbar>
        </div>
    </section>
</div>
