import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

import { Subject } from 'rxjs';

import { AccountService } from '../../../account/account.service';
import { NotificationService } from '../../../_services/notification.service';
import { PartManagerConfigurarFornecimentoService } from '../../configurar-fornecimento/configurar-fornecimento.service';

import { Globals } from '../../../_models/globals';
import { HoldingShipping } from '../../_models/holdingShipping';
import { ApiResponseModel } from 'app/_models/apiResponseModel';

declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-configurar-frete', templateUrl: './configurar-frete.component.html', styleUrls: ['./configurar-frete.component.scss'] })
export class PartManagerConfigurarFreteComponent implements OnInit
{
    @Input() fretes: HoldingShipping[];
    @Input() inicializar: Subject<boolean>;
    @Input() regioes: any;

    adicionando: boolean;
    freteForm: UntypedFormGroup;
    holding_id: number;

    constructor(
        private accountService: AccountService,
        private notificationService: NotificationService,
        private fornecimentoService: PartManagerConfigurarFornecimentoService,
        private globals: Globals,
        private fb: UntypedFormBuilder) {
        this.holding_id = this.accountService.obterUsuario().holding_id;

        this.fretes = [];
        this.regioes = [];
    }

    ngOnInit(): void {
        this.freteForm = this.fb.group(
            {
                regiao: [0, [Validators.required, Validators.min(1)]],
                frete_gratuito: [0, [Validators.min(0)]],
                frete: [0, [Validators.min(0)]],
                frete_conta_comprador: [2, [Validators.required]]
            });

        this.adicionando = true;

        if (!isEmpty(this.regioes))
            this.resetForm(0);

        this.inicializar.subscribe(() => this.resetForm(0));
    }

    adicionar(nAttempts: number) {
        this.adicionando = true;
        let region_id = parseInt(this.freteForm.value.regiao);

        let region_name = this.regioes.find(r => r.region_id === region_id).region_name;
        let frete_conta_comprador = this.freteForm.value.frete_conta_comprador === '1';

        let frete: HoldingShipping = { id: 0, holding_id: this.holding_id, region_id, region_name, min_value: this.freteForm.value.frete_gratuito, shipping_cost: this.freteForm.value.frete, buyer_payer: frete_conta_comprador, is_active: true };
        this.fretes.push(frete);
        this.fretes.sort(sort_by([{ name: 'region_name' }]));

        this.fornecimentoService.freteAdicionar(frete).subscribe({
            next: (response: ApiResponseModel<number>) => {
                if (!isEmpty(response) && response.success) {
                    this.notificationService.showSuccessToastr(this.globals.translate('part-manager.configurar-fornecimento.frete.mensagens.sucesso.adicionar.corpo'));

                    let n_frete = this.fretes.find(f => f.region_id === region_id);

                    if (!isEmpty(n_frete))
                        n_frete.id = response.result;

                    this.resetForm(0);
                }
                else {
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.frete.mensagens.erro.adicionar.corpo'));
                    this.remover(region_id, 0);
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.frete.mensagens.erro.adicionar.corpo'));
                    return;
                }

                this.remover(region_id, 0);
                this.adicionar(++nAttempts);
            }
        });
    }

    mudarContaComprador() {
        if (this.freteForm.value.frete_conta_comprador !== '1')
            return;

        this.freteForm.patchValue({ frete_gratuito: 0, frete: 0 });
    }

    remover(region_id: number, nAttempts: number) {
        let frete = this.fretes.find(f => f.region_id === region_id);

        if (isEmpty(frete))
            return;

        var idx = this.fretes.indexOf(frete);
        this.fretes.splice(idx, 1);

        this.fornecimentoService.freteRemover(frete.region_id).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (!isEmpty(response) && response.success)
                    this.notificationService.showSuccessToastr(this.globals.translate('part-manager.configurar-fornecimento.frete.mensagens.sucesso.remover.corpo'));

                this.resetForm(0);
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5)
                {
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.frete.mensagens.erro.remover.corpo'));
                    return;
                }

                this.remover(region_id, ++nAttempts);
            }
        });
    }

    resetForm(nAttempts: number) {
        this.freteForm.reset();
        this.freteForm.patchValue({ frete_gratuito: 0, frete: 0, frete_conta_comprador: '2' });

        let filtradas = this.regioesFiltradas;

        if (filtradas.length > 0)
            this.freteForm.patchValue({ regiao: filtradas[0].region_id });
        else {
            if (nAttempts < 5)
                setTimeout(() => this.resetForm(++nAttempts), 200);

            this.freteForm.patchValue({ regiao: 0 });
            return;
        }

        this.adicionando = false;
    }

    validarFreteGratuito() {
        if (this.freteForm.value.frete <= 0)
            this.freteForm.patchValue({ frete_gratuito: 0 });
    }


    get regioesFiltradas() {
        if (isEmpty(this.regioes))
            return [];

        return this.regioes.filter(r => this.fretes.filter(f => f.region_id === r.region_id).length <= 0).sort(sort_by([{ name: 'region_name' }]));
    }
}
