<div class="configurar-frete">
    <section class="tabela-frete">
        <header>
            <div class="linha">
                <div class="col">{{'part-manager.configurar-fornecimento.frete.colunas.regiao' | translate}}</div>
                <div class="col">{{'part-manager.configurar-fornecimento.frete.colunas.conta-comprador' | translate}}</div>
                <div class="col">{{'part-manager.configurar-fornecimento.frete.colunas.valor-frete' | translate}}</div>
                <div class="col">{{'part-manager.configurar-fornecimento.frete.colunas.valor-minimo-gratis' | translate}}</div>
                <div class="col"></div>
            </div>
            <div class="linha">
                <form name="freteForm" class="form-validate" [formGroup]="freteForm" (ngSubmit)="adicionar(0)">
                    <div class="col">
                        <select class="form-select form-select-sm m-b" formControlName="regiao" [disabled]="(adicionando || regioesFiltradas.length === 0) ? 'disabled' : null">
                            @if (regioesFiltradas.length === 0) {
                                <option value="0">{{ 'part-manager.configurar-fornecimento.frete.sem-regiao' | translate}}</option>
                            } @else {
                                @for (regiao of regioesFiltradas; track regiao) {
                                    <option value="{{regiao.region_id}}">{{ regiao.region_name }}</option>
                                }
                            }
                        </select>
                    </div>
                    <div class="col">
                        <select class="form-select form-select-sm m-b" name="frete_conta_comprador" formControlName="frete_conta_comprador" (change)="mudarContaComprador()">
                            <option value="1">{{ 'elemento.generico-select.sim' | translate}}</option>
                            <option value="2" selected>{{ 'elemento.generico-select.nao' | translate}}</option>
                        </select>
                    </div>
                    <div class="col">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text">
                                <span>R$</span>
                            </span>
                            <input type="text" class="form-control form-control-sm" [readonly]="(freteForm.value.frete_conta_comprador === '1') ? 'disabled' : null" name="frete" formControlName="frete" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" maxlength="6" (blur)="validarFreteGratuito()" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text">
                                <span>R$</span>
                            </span>
                            <input type="text" class="form-control form-control-sm" [readonly]="(freteForm.value.frete_conta_comprador === '1' || freteForm.value.frete <= 0) ? 'disabled' : null" name="frete_gratuito" formControlName="frete_gratuito" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" maxlength="6" />
                        </div>
                    </div>
                    <div class="col">
                        <input type="submit" [disabled]="regioesFiltradas.length === 0 ? 'disabled' : null" value="{{'part-manager.configurar-fornecimento.botoes.adicionar' | translate}}" class="btn btn-sm btn-success" />
                    </div>
                </form>
            </div>
        </header>
        <div class="corpo">
            <ng-scrollbar>
                @if (fretes?.length <= 0) {
                    <div class="vazio">
                        {{'part-manager.configurar-fornecimento.frete.vazio' | translate}}
                    </div>
                } @else {
                    @for (frete of fretes; track frete) {
                        <div class="linha">
                            <div class="col truncate">{{ frete.region_name }}</div>
                            <div class="col text-center">{{ (frete.buyer_payer ? 'elemento.generico-select.sim' : 'elemento.generico-select.nao') | translate }}</div>
                            <div class="col text-end">{{ frete.shipping_cost | currency:'R$ ' }}</div>
                            <div class="col text-end">{{ frete.min_value | currency:'R$ ' }}</div>
                            <div class="col">
                                <button class="btn btn-sm btn-danger" (click)="remover(frete.region_id, 0)">
                                    <i class="fa fa-trash"></i>
                                    <span>{{'part-manager.configurar-fornecimento.botoes.remover' | translate}}</span>
                                </button>
                            </div>
                        </div>
                    }
                }
            </ng-scrollbar>
        </div>
    </section>
</div>
