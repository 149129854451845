import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';

import { HoldingFTP } from '../_models/holdingFtp';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class PartManagerAtualizarEstoqueService extends BaseService
{
    private _domain: string = '/part-manager/inventories';
    
    constructor(private accountService: AccountService, private config: SettingsService) { super(); }

    public atualizarEstoque(holdingFtp: HoldingFTP, base64file: string, filename: string) {
        let body: any = Object.assign({}, holdingFtp);
        body.base64file = base64file;
        body.filename = filename;

        return this.config.executePost({ url: this._domain, body });
    }

    public atualizarEstoqueAlternativo(holding_ftp_id: string, holding_id: number, part_type_id: number, base64file: string, filename: string) {
        const body = { id: holding_ftp_id, holding_id: holding_id, part_type_id: part_type_id, base64file, filename };
        return this.config.executePost({ url: `${this._domain}/alternative`, body });
    }

    public carregarDadosHolding() {
        let usuarioLogado = this.usuarioLogado;

        if (isEmpty(usuarioLogado)) {
            this.accountService.logout();
            return EMPTY;
        }

        const holding_id = usuarioLogado.holding_id;

        return this.config.executeGet({ url: `${this._domain}/holdings/${holding_id}` });
    }

    public obterColunas(base64file, filename, extensao) {
        const body = { base64file, filename, extensao };

        return this.config.executePost({ url: `${this._domain}/columns`, body });
    }
}
