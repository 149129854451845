import { Component, OnInit, ElementRef, ViewChildren } from '@angular/core';
import { UntypedFormGroup, FormControlName, UntypedFormBuilder, Validators } from '@angular/forms';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from '../../_models/globals';
import { GenericValidator, ValidationMessages, DisplayMessage } from '../../_utils/generic-form-validation';
import { HoldingCashback } from '../_models/holdingCashback';

import { NotificationService } from '../../_services/notification.service';
import { PartManagerConfigurarCashbackService } from './configurar-cashback.service';

declare const isEmpty: any;

@Component({ selector: 'app-configurar-cashback', templateUrl: './configurar-cashback.component.html', styleUrls: ['./configurar-cashback.component.scss'] })
export class PartManagerConfigurarCashbackComponent implements OnInit
{
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    cashback: any;

    cashbackForm: UntypedFormGroup;
    displayMessage: DisplayMessage = {};
    errors: any[] = [];
    genericValidator: GenericValidator;
    validationMessages: ValidationMessages;

    constructor(private notificationService: NotificationService, private cashbackService: PartManagerConfigurarCashbackService, private globals: Globals, private fb: UntypedFormBuilder, private ngxLoader: NgxUiLoaderService) { }

    ngOnInit(): void
    {
        this.cashbackForm = this.fb.group(
            {
                has_cashback: [false],
                cashback_profit_margin: [0, [Validators.max(10), Validators.min(0)]]
            });

        this.cashback = { com_cashback: 300, desconto_cashback: 0, desconto_original: 30, imposto: 15, taxa_partsfy: 40, valor_peca: 1000, valor_venda_cashback: 1000, valor_venda_original: 700 };

        this.carregarTela(0);
    }

    ngAfterViewChecked(): void
    {
        if (this.cashbackForm.value.cashback_profit_margin > 20)
            this.cashbackForm.patchValue({ cashback_profit_margin: 20 });
    }

    calcularDetalhamento()
    {
        let imposto_descontado = (300 * this.cashback.imposto / 100);
        let cashback_sem_imposto = 300 - imposto_descontado;
        let taxa_vendedor = this.cashbackForm.get('cashback_profit_margin').value;

        let cashback_partsfy = cashback_sem_imposto * (this.cashback.taxa_partsfy / 100);
        let cashback_vendedor = (cashback_sem_imposto - cashback_partsfy) * taxa_vendedor / 100;
        let cashback_comprador = (cashback_sem_imposto - cashback_partsfy) - cashback_vendedor;

        this.cashback.partsfy = cashback_partsfy;
        this.cashback.cashback_efetivo = cashback_sem_imposto;
        this.cashback.imposto_descontado = imposto_descontado;
        this.cashback.comprador = cashback_comprador;
        this.cashback.vendedor = cashback_vendedor;
        this.cashback.vendedor_margem = cashback_vendedor * 100 / cashback_sem_imposto;
    }

    carregarTela(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.cashbackService.carregarDadosHolding().subscribe({
            next: (response: ApiResponseModel<HoldingCashback>) => {
                if (!isEmpty(response) && response.success) {
                    this.cashbackForm.patchValue({ has_cashback: response.result.holdingInfo.has_cashback, cashback_profit_margin: response.result.holdingInfo.cashback_profit_margin });
                    this.cashbackForm.markAsDirty();

                    // Deve setar o imposto atual, taxa_partsfy e depois fazer o cálculo
                    this.cashback.imposto = response.result.holdingInfo.tax;
                    this.cashback.taxa_partsfy = response.result.partsfyCommission;

                    this.calcularDetalhamento();
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-cashback.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarTela(++nAttempts);
            }
        });
    }

    salvar(nAttempts: number) {
        if (this.cashbackForm.dirty && this.cashbackForm.valid) {
            this.ngxLoader.startLoader('loader-principal');

            let has_cashback = this.cashbackForm.value.has_cashback;
            let cashback_profit_margin = has_cashback ? this.cashbackForm.value.cashback_profit_margin : 0;

            this.cashbackService.salvar(has_cashback, cashback_profit_margin).subscribe({
                next: (response: ApiResponseModel<boolean>) => {
                    if (!isEmpty(response) && response.success) {
                        this.notificationService.showSuccessToastr(this.globals.translate('part-manager.configurar-cashback.mensagens.sucesso.salvar.corpo'));
                    }

                    this.ngxLoader.stopLoader('loader-principal');
                },
                error: error => {
                    nAttempts = nAttempts || 1;
                    console.log(error, nAttempts);

                    if (nAttempts >= 5) {
                        this.ngxLoader.stopLoader('loader-principal');
                        this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-cashback.mensagens.erro.carregamento.corpo'));
                        return;
                    }

                    this.carregarTela(++nAttempts);
                }
            });
        }
    }


    get has_cashback(): boolean { return this.cashbackForm.get('has_cashback').value; }

    set has_cashback(value)
    {
        if (value)
            this.cashbackForm.patchValue({ cashback_profit_margin: 0 });
    }
}
