import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Subject } from 'rxjs';

import { AccountService } from '../../../account/account.service';
import { NotificationService } from '../../../_services/notification.service';
import { PartManagerConfigurarFornecimentoService } from '../../configurar-fornecimento/configurar-fornecimento.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from '../../../_models/globals';
import { HoldingDeliveryTime } from '../../_models/holdingDeliveryTime';

declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-configurar-prazo-entrega', templateUrl: './configurar-prazo-entrega.component.html', styleUrls: ['./configurar-prazo-entrega.component.scss']})
export class PartManagerConfigurarPrazoEntregaComponent implements OnInit
{
    @Input() dados: { alterando: boolean, dias_pedido_fabrica: number, resposta_automatica: boolean };
    @Input() prazos: HoldingDeliveryTime[];
    @Input() prazos_entrega: any;
    @Input() inicializar: Subject<boolean>;
    @Input() regioes: any;
    @Output() alterarGeral = new EventEmitter();

    adicionando: boolean;
    prazoForm: UntypedFormGroup;
    holding_id: number;

    constructor(
        private accountService: AccountService,
        private notificationService: NotificationService,
        private fornecimentoService: PartManagerConfigurarFornecimentoService,
        private globals: Globals,
        private fb: UntypedFormBuilder) {
        this.holding_id = this.accountService.obterUsuario().holding_id;

        this.prazos = [];
        this.prazos_entrega = [];
        this.regioes = [];
    }

    ngOnInit(): void {
        this.prazoForm = this.fb.group(
            {
                region_id: [0, [Validators.required, Validators.min(1)]],
                delivery_time_id: [0, [Validators.min(0)]]
            });

        this.adicionando = true;

        if (!isEmpty(this.regioes))
            this.resetForm(0);

        this.inicializar.subscribe(() => this.resetForm(0));
    }

    adicionar(nAttempts: number) {
        this.adicionando = true;
        let region_id = parseInt(this.prazoForm.value.region_id);
        let region_name = this.regioes.find(r => r.region_id === region_id).region_name;

        let delivery_time_id = parseInt(this.prazoForm.value.delivery_time_id);
        let delivery_time_name = this.prazos_entrega.find(p => p.delivery_time_id === delivery_time_id).delivery_time_name;

        let prazo: HoldingDeliveryTime = { id: 0, holding_id: this.holding_id, region_id, region_name, delivery_time_id: delivery_time_id, delivery_time_name: delivery_time_name, is_active: true };
        this.prazos.push(prazo);
        this.prazos.sort(sort_by([{ name: 'region_name' }]));

        this.fornecimentoService.prazoEntregaAdicionar(prazo).subscribe({
            next: (response: ApiResponseModel<number>) => {
                if (!isEmpty(response) && response.success) {
                    this.notificationService.showSuccessToastr(this.globals.translate('part-manager.configurar-fornecimento.prazo-entrega.mensagens.sucesso.adicionar.corpo'));

                    let n_prazo = this.prazos.find(f => f.region_id === region_id);

                    if (!isEmpty(n_prazo))
                        n_prazo.id = response.result;

                    this.resetForm(0);
                }
                else {
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.prazo-entrega.mensagens.erro.adicionar.corpo'));
                    this.remover(region_id, 0);
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.prazo-entrega.mensagens.erro.adicionar.corpo'));
                    return;
                }

                this.remover(region_id, 0);
                this.adicionar(++nAttempts);
            }
        });
    }

    alterar() {
        this.alterarGeral.emit({ diasPedidoFabrica: this.dados.dias_pedido_fabrica, respostaSemRevisao: this.dados.resposta_automatica, tipo: '-dias-pedido-fabrica' });
    }

    remover(region_id: number, nAttempts: number) {
        let prazo = this.prazos.find(f => f.region_id === region_id);

        if (isEmpty(prazo))
            return;

        var idx = this.prazos.indexOf(prazo);
        this.prazos.splice(idx, 1);

        this.fornecimentoService.prazoEntregaRemover(prazo.region_id).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (!isEmpty(response) && response.success)
                    this.notificationService.showSuccessToastr(this.globals.translate('part-manager.configurar-fornecimento.prazo-entrega.mensagens.sucesso.remover.corpo'));

                this.resetForm(0);
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.configurar-fornecimento.prazo-entrega.mensagens.erro.remover.corpo'));
                    return;
                }

                this.remover(region_id, ++nAttempts);
            }
        });
    }

    resetForm(nAttempts: number) {
        this.prazoForm.reset();
        this.prazoForm.patchValue({ delivery_time_id: 1 });

        let filtradas = this.regioesFiltradas;

        if (filtradas.length > 0)
            this.prazoForm.patchValue({ region_id: filtradas[0].region_id });
        else {
            if (nAttempts < 5)
                setTimeout(() => this.resetForm(++nAttempts), 200);

            this.prazoForm.patchValue({ region_id: 0 });
            return;
        }

        this.adicionando = false;
    }


    get alterando(): boolean {
        return this.dados.alterando;
    }

    get dias_pedido_fabrica() : string {
        return this.dados.dias_pedido_fabrica.toString();
    }

    set dias_pedido_fabrica(valor) {
        this.dados.dias_pedido_fabrica = parseInt(valor);
    }

    get regioesFiltradas() {
        if (isEmpty(this.regioes))
            return [];

        return this.regioes.filter(r =>
            this.prazos.filter(f => f.region_id === r.region_id).length <= 0).sort(sort_by([{ name: 'region_name' }]));
    }
}
